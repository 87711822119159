import React, { useContext } from 'react';
import * as S from './PricePerChewV1.styles';
import FunnelContext from '../../context/FunnelContext';
import { ProductPrice } from '../../utils/productPrice';
import { Builder } from '@builder.io/react';

const PricePerChew = props => {
  const { showPricePer, pricePerText } = props;
  const context = useContext(FunnelContext);
  const { currentCategory, currentProduct, pageDesign, currentTub } = context;

  const { quantity, chewsPerQuantity } = currentTub;
  const totalQuantity = parseInt(quantity || 1);
  const totalChews = chewsPerQuantity * totalQuantity || 30;
  const discountedPrice = ProductPrice({
    type: `discounted_price`,
    currentCategory: currentCategory,
    currentProduct: currentProduct
  });

  const PricePerContainer = () => {
    switch (showPricePer) {
      case 'tub':
        return <>${(discountedPrice / totalQuantity).toFixed(2)}</>;
      default:
      case 'chew':
        return <>${(discountedPrice / totalChews).toFixed(2)}</>;
    }
  };

  if (showPricePer === 'tub' && totalQuantity === 1) {
    const onBuilder = Builder.isPreviewing || Builder.isEditing;
    return onBuilder ? (
      <small>
        <strong>PRICE PER TUB</strong>
        <br />
        HIDDEN ON 1 {pricePerText}
      </small>
    ) : (
      ''
    );
  } else {
    return (
      <S.Container pageDesign={pageDesign}>
        <S.Quantity>
          {totalQuantity || 1} Tub{totalQuantity > 1 ? `s` : ``} / {totalChews}{' '}
          Chews
        </S.Quantity>
        <S.Price>
          <PricePerContainer /> per {pricePerText || 'chew'}
        </S.Price>
      </S.Container>
    );
  }
};

export default PricePerChew;
