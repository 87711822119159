import styled from 'styled-components';

export const Container = styled.span`
  display: block;
  white-space: nowrap;
`;

export const Quantity = styled.span`
  display: block;
  white-space: nowrap;
  font-size: 14px;
  text-align: right;

  @media (max-width: 375px) {
    font-size: 12px;
  }
  @media (max-width: 320px) {
    font-size: 10px;
  }
`;

export const Price = styled.span`
  display: block;
  white-space: nowrap;
  font-size: 20px;

  @media (max-width: 375px) {
    font-size: 18px;
  }
  @media (max-width: 320px) {
    font-size: 16px;
  }
`;
